import { Box, Collapse } from '@mui/material';
import { DateInput, SelectInput, TextInput, minLength } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from 'hooks';
import { paymentModes, paymentStates } from 'modules/parcels/constants';

export interface ParcelsListFiltersExpandedProps {
    expanded: boolean;
    inputWidth: string;
}

export const ParcelsListFiltersExpanded = ({ expanded, inputWidth }: ParcelsListFiltersExpandedProps) => {
    const translate = useTranslate();
    const form = useFormContext();

    return (
        <Collapse in={expanded} unmountOnExit>
            <Box display="grid" gridTemplateColumns={{ xs: '1fr', md: `repeat(3, ${inputWidth})` }} columnGap={2}>
                <TextInput label={translate('parcels.list.reference')} source="referenceNumber" resettable />
                <TextInput
                    label={translate('parcels.list.customerName')}
                    source="customerName"
                    validate={minLength(3)}
                    resettable
                />
                <TextInput
                    label={translate('parcels.list.customerEmail')}
                    type="email"
                    source="customerEmail"
                    resettable
                    validate={minLength(3)}
                />
            </Box>

            <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', md: `repeat(4, ${inputWidth})` }} columnGap={2}>
                <DateInput label={translate('parcels.list.search.from')} source="from" />
                <DateInput label={translate('parcels.list.search.to')} source="to" />

                <SelectInput
                    label={translate('parcels.payment')}
                    source="paymentMode"
                    choices={paymentModes.map(paymentMode => ({
                        id: paymentMode,
                        name: translate(`parcel.payment.method.${paymentMode}`),
                    }))}
                    resettable
                />

                {form.watch('paymentMode') === 'cod' && (
                    <SelectInput
                        label={translate('parcels.payment.status')}
                        source="paymentState"
                        choices={paymentStates.map(paymentState => ({
                            id: paymentState,
                            name: translate(`parcel.payment.state.${paymentState}`),
                        }))}
                        resettable
                    />
                )}
            </Box>
        </Collapse>
    );
};
