import { required, useTranslate, SaveButton, SimpleForm } from 'react-admin';
import type { Validator, SimpleFormProps } from 'react-admin';
import type { FieldValues } from 'react-hook-form';

import { Toolbar, Box, Typography, Grid } from '@mui/material';

import { TextInput, PhoneInput, AddButton, EmailInput, RegionsListInput } from 'modules/ui';
import { useCurrentRegionValidator } from 'modules/localization';
import { nonNullParser } from 'utils';

import EnablePartnerButton from '../EnablePartnerButton';
import { PartnerCharacteristics } from '../PartnerCharacteristics';
import { PartnerPermissions } from '../PartnerPermissions';
import { EditableWarehousesList } from '../EditableWarehousesList';
import { ContractRegion } from '../ContractRegion';
import { WarehouseEditProvider } from '../WarehouseEditProvider';

import * as Styled from './PartnerForm.styles';

const requiredValidator = required();

const shippingLabelValidator: Validator = value => {
    if (value) {
        const rows = value.split('\n');

        if (rows.length > 4) {
            return 'partners.shippingLabel.error.maxRows';
        }

        const rowOver20Index = rows.findIndex((row: string) => row.length > 20);

        if (rowOver20Index > -1) {
            return { message: 'partners.shippingLabel.error.maxChars', args: { row: rowOver20Index + 1 } };
        }
    }

    return undefined;
};

export interface PartnerFormProps extends Omit<SimpleFormProps, 'toolbar' | 'children'> {
    onSubmit: (values: FieldValues) => Promise<any>;
}

const PartnerForm = (props: PartnerFormProps) => {
    const translate = useTranslate();

    const currentRegionValidator = useCurrentRegionValidator();

    return (
        <SimpleForm
            {...props}
            toolbar={
                <Toolbar>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <SaveButton />
                        <EnablePartnerButton />
                    </Box>
                </Toolbar>
            }
        >
            <Styled.SetupSection>
                <Grid container spacing={4}>
                    <Grid item>
                        <PartnerCharacteristics />
                    </Grid>
                    <Grid item>
                        <PartnerPermissions />
                    </Grid>
                </Grid>
            </Styled.SetupSection>
            <Typography variant="h6" component="h3">
                {translate('partners.details.title')}
            </Typography>

            <ContractRegion />

            <TextInput source="name" label={translate('partners.name')} validate={requiredValidator} />

            <RegionsListInput
                source="shippingRegions"
                label={translate('partners.region.shipping')}
                placeholder={translate('action.choose')}
                validate={[requiredValidator, currentRegionValidator]}
            />

            <TextInput
                source="shippingLabelInfo"
                multiline
                rows={4}
                label={translate('partners.shippingLabel')}
                helperText={translate('partners.shippingLabel.helperText')}
                validate={[requiredValidator, shippingLabelValidator]}
            />
            <EmailInput source="email" label={translate('partners.email')} validate={[requiredValidator]} />
            <TextInput source="vatNumber" label={translate('partners.vat')} parse={nonNullParser} />
            <TextInput source="iban" label={translate('partners.iban')} parse={nonNullParser} />

            <Typography variant="h6" component="h3">
                {translate('partners.allowedUsers.title')}
            </Typography>

            <Styled.NumbersArrayInput source="allowedUsers" label={''}>
                <Styled.NumbersIterator addButton={<AddButton label="partners.allowedUsers.add" />} disableReordering>
                    <PhoneInput source="" label={translate('users.phone')} validate={requiredValidator} />
                </Styled.NumbersIterator>
            </Styled.NumbersArrayInput>

            <Typography variant="h6" component="h3" mt={3}>
                {translate('warehouse.title')}
            </Typography>

            <WarehouseEditProvider>
                <EditableWarehousesList />
            </WarehouseEditProvider>
        </SimpleForm>
    );
};

export default PartnerForm;
