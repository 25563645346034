import { Box } from '@mui/material';
import { SelectInput, TextInput, minLength, number } from 'react-admin';

import { useTranslate } from 'hooks';
import { parcelStatusOrdered } from 'modules/parcels/config';

import { ParcelCustomerPhoneInputGroup } from '../ParcelCustomerPhoneInputGroup';

export interface ParcelsListFiltersCollapsedProps {
    inputWidth: string;
}

export const ParcelsListFiltersCollapsed = ({ inputWidth }: ParcelsListFiltersCollapsedProps) => {
    const translate = useTranslate();
    const PHONE_INPUT_WIDTH = '240px';

    return (
        <Box
            display={'grid'}
            gridTemplateColumns={{
                xs: '1fr',
                md: `minmax(auto, ${inputWidth}) ${PHONE_INPUT_WIDTH} repeat(3, minmax(auto, ${inputWidth}))`,
            }}
            columnGap={2}
        >
            <TextInput
                label={translate('parcels.list.number')}
                source="parcelNumber"
                resettable
                validate={[number(), minLength(4)]}
            />
            <ParcelCustomerPhoneInputGroup />
            <TextInput label={translate('parcels.list.partner')} source="partnerName" resettable />
            <SelectInput
                label={translate('parcels.list.status')}
                source="status"
                choices={parcelStatusOrdered.map(status => ({
                    id: status,
                    name: translate(`parcel.state.${status}`),
                }))}
                resettable
            />
            <SelectInput
                label={translate('parcels.list.customerIssue')}
                source="customerIssue"
                choices={(['never', 'over', 'active'] as const).map(status => ({
                    id: status,
                    name: translate(`parcels.customerIssue.${status}` as const),
                }))}
                resettable
            />
        </Box>
    );
};
