import { useListContext } from 'react-admin';

const EXPANDED_FIELDS = [
    'referenceNumber',
    'customerName',
    'customerEmail',
    'from',
    'to',
    'paymentMode',
    'paymentState',
];

export const useActiveFiltersCount = () => {
    const { filterValues } = useListContext();

    const activeFilters = Object.keys(filterValues);
    const activeExpandedFilters = activeFilters.filter(key => EXPANDED_FIELDS.includes(key));

    const activeFiltersCount = activeFilters.length;
    const activeExpandedFiltersCount = activeExpandedFilters.length;
    const activeCollapsedFiltersCount = activeFiltersCount - activeExpandedFiltersCount;

    return {
        activeFiltersCount,
        activeExpandedFiltersCount,
        activeCollapsedFiltersCount,
    };
};
