import { memo } from 'react';
import { BooleanInput, useTranslate, Link, SelectInput, required } from 'react-admin';
import { Typography, Grid, Link as MUILink } from '@mui/material';

import { compartmentSizes } from 'modules/apm/constants';

export interface ApmCompartmentProps {
    id: string;
    compartmentNumber: number;
    storedParcel?: {
        id: string;
        parcelNumber: string;
    };

    getSource: (source: string) => string;
}

export const ApmCompartment = memo(
    ({ getSource, compartmentNumber, id, storedParcel }: ApmCompartmentProps) => {
        const translate = useTranslate();

        return (
            <Grid key={id} container alignItems="center" spacing={4} width="100%">
                <Grid item xs={1} sm={1} md="auto">
                    <Typography variant="subtitle1">{compartmentNumber}</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md="auto" mt={2}>
                    <SelectInput
                        source={getSource('size')}
                        label={translate('apm.edit.compartments.size')}
                        choices={compartmentSizes}
                        validate={required()}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md="auto" mt={2}>
                    <BooleanInput source={getSource('availability')} label={translate('apm.list.availability')} />
                </Grid>

                {storedParcel && (
                    <Grid item xs={3} sm={3} md="auto">
                        <MUILink>
                            <Link to={`/parcel/${storedParcel.id}/show`}>
                                {`${translate('apm.edit.compartments.storedParcel')} (${storedParcel.parcelNumber})`}
                            </Link>
                        </MUILink>
                    </Grid>
                )}
            </Grid>
        );
    },
    () => false,
);
