import type { FC } from 'react';
import { Datagrid, DateField, FunctionField, TextField, useTranslate } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { ListWithPermissions, useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';
import { SeoHead } from 'modules/ui';

import { ParcelsListActions } from '../ParcelsListActions';
import { ParcelsListFilters } from '../ParcelsListFilters';

import * as Styled from './ParcelsList.styles';

const ParcelsList: FC = props => {
    const translate = useTranslate();
    const hasDetailAccess = useHasAccess(ResourceKey.PARCELS, PermissionAction.DETAIL);

    return (
        <>
            <SeoHead title={translate('parcels.list.title')} />
            <ListWithPermissions
                {...props}
                title={translate('parcels.list.title')}
                sort={{ field: 'id', order: 'DESC' }}
                queryOptions={{
                    // Note: To reduce the amount of queries increase staleTime to 2 minutes
                    // https://react-query-v3.tanstack.com/reference/useQuery
                    staleTime: 1000 * 60 * 2,
                    meta: {
                        isPartiallyPaginated: true,
                    },
                }}
                actions={false}
                filters={
                    <Styled.ActionsAndFiltersContainer>
                        <ParcelsListActions />
                        <ParcelsListFilters />
                    </Styled.ActionsAndFiltersContainer>
                }
            >
                <Datagrid rowClick={hasDetailAccess ? 'show' : undefined} bulkActionButtons={false}>
                    <TextField source="parcelNumber" label={translate('parcels.list.number')} />
                    <TextField source="partnerName" label={translate('parcels.list.partner')} />
                    <TextField source="referenceNumber" label={translate('parcels.list.reference')} />
                    <TextField source="customerName" sortable={false} label={translate('parcels.list.customerName')} />
                    <TextField
                        source="customerPhoneNumber"
                        sortable={false}
                        label={translate('parcels.list.customerPhoneNumber')}
                    />
                    <DateField
                        source="createdAt"
                        showTime
                        label={translate('parcels.list.created')}
                        options={{ dateStyle: 'short', timeStyle: 'short' }}
                    />
                    <DateField
                        source="updatedAt"
                        showTime
                        label={translate('parcels.list.updated')}
                        options={{ dateStyle: 'short', timeStyle: 'short' }}
                    />
                    <FunctionField
                        source="locationType"
                        label={translate('parcels.list.location')}
                        render={(record: any) =>
                            record.locationType && translate(`parcels.location.${record.locationType}`)
                        }
                    />
                    <FunctionField
                        source="paymentMode"
                        sortable={false}
                        label={translate('parcels.payment')}
                        render={(record: any) => translate(`parcels.payment.${record.payment.mode}`)}
                    />
                    <FunctionField
                        source="status"
                        label={translate('parcels.list.status')}
                        render={(record: any) => translate(`parcel.state.${record.status}`)}
                    />
                </Datagrid>
            </ListWithPermissions>
        </>
    );
};

export default ParcelsList;
